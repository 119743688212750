// @import "../../../size.mixin.scss";

// .integrationMainWrapper {
//     padding: 29px 44px 10px 31px;
//     border-radius: 12px;
//     border: 0.5px solid #EAEAEA;
//     background: #FFF;
//     box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.07);

//     .integrationMain {

//         .integrationHeader {
//             display: flex;
//             justify-content: space-between;
//             align-items: center;
//             margin-bottom: 24px;

//             @include for-size(ms) {
//                 flex-direction: column;
//                 gap: 1rem;
//                 align-items: flex-start;
//             }

//             h3 {
//                 color: #101828;

//                 font-size: 18px;
//                 font-style: normal;
//                 font-weight: 500;
//                 line-height: 28px;
//             }

//             button {
//                 border-radius: 8px;
//                 border: 1px solid var(--gray-modern-200, #E3E8EF);
//                 box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

//                 display: flex;
//                 gap: 1rem;
//                 padding: 10px 18px;

//                 p {
//                     text-transform: none;
//                     color: #364152; //--gray--modern--700
//                     font-size: 16px;
//                     font-style: normal;
//                     font-weight: 600;
//                     line-height: 24px;
//                 }
//             }
//         }

//         .addAccount {
//             border-radius: 12px;
//             background: #EFF0F7;
//             padding: 24px 24px 8px 24px;
//         }

//         .integrationDataWrapper {
//             margin-top: 12px;

//             .integratedData {
//                 display: flex;
//                 justify-content: space-between;
//                 align-items: center;
//                 padding: 12px 0;
//                 border-bottom: 1px solid #EAECF0;

//                 @include for-size(ms) {
//                     flex-direction: column;
//                     align-items: flex-start;
//                 }

//                 .integratedInfo {
//                     display: flex;
//                     align-items: flex-start;
//                     gap: 5px;

//                     font-style: normal;
//                     font-weight: 500;
//                     line-height: 18px;

//                     .icon {
//                         width: 20.941px;
//                         height: 20.941px;
//                         background: #e9effa;
//                         border-radius: 50%;
//                         display: flex;
//                         align-items: center;
//                         justify-content: center;


//                         img{
//                             width: 14px;
//                             height: 14px;
//                         }
//                     }

//                     h4 {
//                         overflow: hidden;
//                         color: #1D2939;
//                         text-overflow: ellipsis;
//                         font-size: 13px;
//                     }

//                     p {
//                         color: #8391A2;
//                         font-size: 11px;
//                     }

//                     .id {
//                         color: #8391A2;
//                         font-size: 9px;
//                         font-weight: 600;
//                     }
//                 }
//             }

//             .integratedData:last-child {
//                 border-bottom: 0px;
//             }
//         }
//     }
// }


@import '../../../size.mixin.scss';

.cardsContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  .cardData {
    border: 1px solid #ededed;
    background: #fff;
    padding: 20px;
    border-radius: 5px;

    .cardImg {
      img {
        width: 30px;
        height: 30px;
      }
    }
    .cardText {
      .cardTitle {
        color: #000;
        font-size: 19px;
        font-weight: 600;
      }
    }

    .connectBtn {
      padding: 4px 10px;
      border-radius: 5px;
    }
  }

  @include for-size(sm) {
    grid-template-columns: repeat(1, 1fr);
  }
}
