@import '../../../size.mixin.scss';

.cardsContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  .cardData {
    border: 1px solid #ededed;
    background: #fff;
    padding: 20px;
    border-radius: 5px;

    .cardImg {
      img {
        width: 30px;
        height: 30px;
      }
    }
    .cardText {
      .cardTitle {
        color: #000;
        font-size: 19px;
        font-weight: 600;
      }
    }

    .connectBtn {
      padding: 4px 10px;
      border-radius: 5px;
    }
  }

  @include for-size(sm) {
    grid-template-columns: repeat(1, 1fr);
  }
}
