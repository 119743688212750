@import '../../size.mixin.scss';

.signUpContainer {
  display: flex;
  height: 100vh;
  background-color: #f4f3fc;

  @include for-size(md) {
    .loginLeft{
      width: 100%;
    }
  }


  @include for-size(md) {
    .loginRight{
      display: none;
    }
  }
}

.loginLeft {
  width: 50%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.logo {
  width: 150px;
  margin-bottom: 20px;
}

h2 {
  color: #7c4eff;
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

form {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;

  label {
    color: #333;
    font-weight: 600;
    margin: 0;
  }

  input {
    padding: 0.8rem;
    border: none;
    outline: none;
    border-bottom: 2px solid #ddd;
    font-size: 1rem;
    transition: border-bottom 0.5s ease;
    border-radius: 0;
    margin: 0;
  }

  input:focus {
    border-bottom: 2px solid #1c1919;
  }

  button {
    background-color: #7c4eff;
    color: #fff;
    padding: 0.8rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    margin: 1rem 0;
    border-radius: 20px;
  }
  button:hover {
    background-color: #6b3fdc;
  }
}

.authOptions {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;

  .shopifyBtn,
  .stripeBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
    gap: 10px;
    // padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;

    img {
      width: 15px;
      height: 15px;
    }

    &.shopifyBtn {
      background-color: #f3f7fa;
      color: #5eab37;
    }

    &.stripeBtn {
      background-color: #f3f7fa;
      color: #4b89da;
    }
  }
}

.loginRight {
  width: 50%;
  background: linear-gradient(40deg, #e7d3ff, #bc8cff);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;

  

  .dashboardImage {
    width: 80%;
    border-radius: 20px;
    object-fit: cover;
    margin-bottom: 1.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  

  h3 {
    color: #555;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  p {
    color: #888;
    font-size: 1rem;
  }
}

p {
  color: #666;
  font-size: 0.9rem;
  margin: 1rem 0;
}
