@import '../../size.mixin.scss';

.disputesWrapper {
    background: #e8e8e8;
    width: 100%;
    height: 100vh;
    padding: 22.68px 48px 55px 55px;

    @include for-size(lg) {
        padding: 32px 30px;
        gap: 10px;
    }

    @include for-size(xs) {
        padding: 20px;
    }

    .nav {
        display: flex;
        gap: 40px;
        flex-wrap: wrap;
        margin-bottom: 21.99px;

        @include for-size(xs) {
            gap: 10px;
        }

        h3 {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: #101828;
        }

        .navPath {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 18px;

            p {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #8391a2;
            }

            p:last-child {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #18408e;
            }

            .cursor {
                cursor: pointer;
              }
        }
    }

    .boxWrapper {
        // max-width: 793px;
        width: 100%;

        .tabsWrapper {
            border-radius: 12px;
            border: 0.5px solid #EAEAEA;
            background: #FFF;
            box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.07);
            padding-left: 21px;

            .integrationTabs {
                display: flex;
                gap: 16px;

                .integrationTab {
                    text-transform: capitalize;
                    border-radius: 0;
                    color: #8391A2;
                    // padding: 12px 10px 15px;
                }

                .activeTab {
                    border-bottom: 2px solid #18408E;
                    color: #18408E;
                }
            }
        }

        .customWrapper {
            height: 75vh;
            overflow-y: auto;
            // padding: 19px 0;
            margin-top: 20px;
        }

    }
}